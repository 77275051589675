import { Component, createRef } from 'react';
import autoBind from 'react-autobind';
import axios from 'axios';
import Cookies from 'js-cookie';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';

import { isValidEmail } from '../../helpers/commonHelpers';
import { getCsrfToken } from '../../helpers/railsHelpers';
import { createWatchlistOnSubmit, savePreferencesOnSubmit } from '../../helpers/authenticationHelpers';

import { setUserHasAccountModal } from '../../actions/authentication.actions';

import OnboardingStepNav from '../Onboarding/OnboardingStepNav';

import StorageSvc from '../../services/StorageSvc';

import Styles from './styles/styles.module.scss';

const cx = classNames.bind(Styles);

class RegistrationComponent extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      termsChecked: false,
      alertsChecked: false,
      occasionalChecked: false,
      errorMessage: '',
      errorFirstName: false,
      errorLastName: false,
      errorEmail: false,
      errorPassword: false,
      errorConfirmPassword: false,
      errorTerms: false,
      activeBtn: false,
      fieldsErrors: {
        firstName: 'Min. 2 symbols',
        lastName: 'Min. 2 symbols',
        email: 'Incorrect email format',
        password: 'Min. 8 symbols',
        confirmPassword: 'Passwords don\'t match',
      },
    };

    this.form = createRef();
  }

  handleChangeFields(fieldName) {
    const { termsChecked } = this.state;

    const firstName = this.firstName?.value || '';
    const lastName = this.lastName?.value || '';
    const email = this.email?.value;
    const password = this.password?.value;
    const passwordConfirmation = this.passwordConfirmation?.value;

    this.setState({
      errorMessage: '',
    });
    const regexIsEmail = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;

    if (fieldName === 'firstName'
    && firstName.length && (firstName.length < 2 || !this.validName(firstName))) {
      this.setState({
        errorFirstName: true,
      });
    } else if (fieldName === 'firstName') {
      this.setState({
        errorFirstName: false,
      });
    }
    if (fieldName === 'lastName'
    && lastName.length && (lastName.length < 2 || !this.validName(lastName))) {
      this.setState({
        errorLastName: true,
      });
    } else if (fieldName === 'lastName') {
      this.setState({
        errorLastName: false,
      });
    }
    if (fieldName === 'email'
    && (!email.length
      || (email.length && (email.indexOf('@') === -1 || email.indexOf('.') === -1))
      || !regexIsEmail.test(email))) {
      this.setState({
        errorEmail: true,
      });
    } else if (fieldName === 'email') {
      this.setState({
        errorEmail: false,
      });
    }
    if (fieldName === 'password'
    && password.length && password.length < 8) {
      this.setState({
        errorPassword: true,
      });
    } else if (fieldName === 'password') {
      this.setState({
        errorPassword: false,
        errorConfirmPassword: password !== passwordConfirmation,
      });
    }
    if (fieldName === 'passwordConfirmation'
    && passwordConfirmation.length && (password !== passwordConfirmation)) {
      this.setState({
        errorConfirmPassword: true,
        fieldsErrors: {
          ...this.state.fieldsErrors,
          confirmPassword: !passwordConfirmation.length ? 'Min. 8 symbols' : 'Passwords don\'t match',
        },
      });
    } else if (fieldName === 'passwordConfirmation') {
      this.setState({
        errorConfirmPassword: false,
      });
    }

    const validationResults = {
      firstName: firstName.length >= 2 && this.validName(firstName),
      lastName: lastName.length >= 2 && this.validName(lastName),
      email: isValidEmail(email),
      password: password.length >= 8,
      passwordConfirmation: password === passwordConfirmation,
      termsChecked,
    };

    const isValidFields = Object.values(validationResults).every(Boolean);

    this.setState({ activeBtn: isValidFields });
  }

  checkTerms() {
    const { termsChecked } = this.state;

    this.setState({ termsChecked: !termsChecked }, this.handleChangeFields);
  }

  checkAlerts() {
    const { alertsChecked } = this.state;

    this.setState({ alertsChecked: !alertsChecked });
  }

  checkOccasional() {
    const { occasionalChecked } = this.state;

    this.setState({ occasionalChecked: !occasionalChecked });
  }

  validName(str) {
    return !/[~`@!#$%\^&*+=\-\[\];,/{}|\\":<>\?]/g.test(str);
  }

  checkFields(firstName, lastName, email, password, passwordConfirmation) {
    const { isFinpromptPages } = this.props;

    const regexIsEmail = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;

    if (firstName.length < 2 || !this.validName(firstName)) {
      return this.setState({
        errorMessage: 'Please enter a valid first name',
        errorFirstName: true,
      });
    }
    if (lastName.length < 2 || !this.validName(lastName)) {
      return this.setState({
        errorMessage: 'Please enter a valid last name',
        errorLastName: true,
      });
    }
    if (email.indexOf('@') === -1 || email.indexOf('.') === -1 || !regexIsEmail.test(email)) {
      return this.setState({
        errorMessage: 'Please enter a valid email address',
        errorEmail: true,
      });
    }
    if (password.length < 8) {
      return this.setState({
        errorMessage: 'Password needs to be at least 8 characters long',
        errorPassword: true,
      });
    }
    if (password !== passwordConfirmation) {
      return this.setState({
        errorMessage: 'Passwords don\'t match. Please try again',
        errorConfirmPassword: true,
        fieldsErrors: {
          ...this.state.fieldsErrors,
          confirmPassword: !passwordConfirmation.length ? 'Min. 8 symbols' : 'Passwords don\'t match',
        },
      });
    }

    if (isFinpromptPages) {
      this.sendLoginAction('Register Onboarding');
      this.submitRegistration();
    } else {
      axios.get('/users/email_present.json', {
        params: {
          user_email: email,
        },
      }).then(({ data }) => {
        if (data.error) {
          return this.setState({ errorMessage: data.error || 'Ops, something went wrong' });
        }

        if (data.email_present === true) {
          this.setState({ errorMessage: 'Email already exists' });
        } else {
          this.sendLoginAction('Register Onboarding');
          this.submitRegistration();
        }
      })
        .catch((error) => {
          console.log(error.message);
          if (error.response?.data?.error) {
            return this.setState({ errorMessage: error.response.data.error });
          }
        });
    }
  }

  submitRegistration() {
    const { occasionalChecked, alertsChecked } = this.state;
    const {
      auth, redirectPath,
      onboarding, isFinpromptPages,
      setUserHasAccountModal,
    } = this.props;

    const firstName = this.firstName?.value;
    const lastName = this.lastName?.value;
    const email = this.email?.value;
    const password = this.password?.value;
    const csrfToken = getCsrfToken();

    const languageFilter = JSON.parse(StorageSvc.getItem('LanguageFilter')) || {};

    const languages = [];
    languageFilter?.dropdown?.selected?.map((item) => languages.push(item.short));

    const dataFinPromptRequest = {
      authenticity_token: auth,
      redirect_path: redirectPath,
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      password_confirmation: password,
      email_opt_in_comms: occasionalChecked,
      email_wl_alerts: alertsChecked,
      landing_platform: 'finprompt',
    };
    const dataCf = {
      user: {
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        password_confirmation: password,
        email_opt_in_comms: occasionalChecked,
        email_wl_alerts: alertsChecked,
      },
      authenticity_token: auth,
      redirect_path: redirectPath,
    };
    const currentData = isFinpromptPages ? dataFinPromptRequest : dataCf;

    const signUpPath = isFinpromptPages ? '/webapi/v1/users/sign_up' : '/users';

    axios.post(signUpPath, currentData, {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
    })
      .then(({ data }) => {
        if (onboarding) {
          createWatchlistOnSubmit();
        }

        let selectedLanguages;
        try {
          selectedLanguages = JSON.parse(StorageSvc.getItem('state')).storyFilters.languages;
          if (!selectedLanguages?.trim()) {
            throw new Error();
          }
        } catch {
          selectedLanguages = [
            ...new Set([
              'en',
              typeof window === 'object' ? window.navigator.language.split('-')[0] : 'en',
            ]),
          ].join(',');
        }
        if (redirectPath === '/chatbot') {
          StorageSvc.setItem('show_demo_chatbot_status', 'true');
        }
        savePreferencesOnSubmit({
          onboarding,
          data,
          redirectPath,
          registerSubmit: true,
          selectedLanguages,
          isFinpromptPages,
        });
        if (isFinpromptPages) {
          Cookies.set('api_token', data.token, { expires: 365 });
        }
      })
      .catch((error) => {
        if (isFinpromptPages && error.response?.data?.error?.toLowerCase()?.includes('user already registered')) {
          setUserHasAccountModal(true);
        } else {
          this.setState({
            errorMessage: error.response?.data?.error || error.message,
          });
        }
      });
  }

  validateRegisterForm(event) {
    event.preventDefault();
    const { termsChecked } = this.state;

    const firstName = this.firstName?.value;
    const lastName = this.lastName?.value;
    const email = this.email?.value;
    const password = this.password?.value;
    const passwordConfirmation = this.passwordConfirmation?.value;

    if (
      firstName.trim().length === 0
      || lastName.trim().length === 0
      || email.trim().length === 0
      || password.trim().length === 0
      || passwordConfirmation.trim().length === 0
      || termsChecked === false
    ) {
      this.setState({
        errorMessage: 'Please complete the required fields',
        errorFirstName: firstName.trim().length < 2,
        errorLastName: lastName.trim().length < 2,
        errorEmail: email.trim().length <= 0,
        errorPassword: password.trim().length < 8,
        errorConfirmPassword: passwordConfirmation.trim().length < 8,
        errorTerms: (termsChecked === false),
        fieldsErrors: {
          ...this.state.fieldsErrors,
          confirmPassword: passwordConfirmation.trim().length < 8 ? 'Min. 8 symbols' : 'Passwords don\'t match',
        },
      });
    } else {
      this.setState({
        errorMessage: '',
        errorFirstName: false,
        errorLastName: false,
        errorEmail: false,
        errorPassword: false,
        errorConfirmPassword: false,
      });
      this.checkFields(firstName, lastName, email, password, passwordConfirmation);
    }
  }

  // piwik action
  sendLoginAction(action) {
    const { piwikEnabled } = this.props;

    if (piwikEnabled) _paq.push(['trackEvent', 'Login', action]);
  }

  onboardingHandleClickRegister() {
    this.form.current.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true }),
    );
  }

  render() {
    const {
      termsChecked,
      alertsChecked,
      occasionalChecked,
      errorMessage,
      errorFirstName,
      errorLastName,
      errorEmail,
      errorPassword,
      errorConfirmPassword,
      errorTerms,
      activeBtn,
      fieldsErrors,
    } = this.state;

    const {
      onboarding, history, piwikEnabled, user, width, signInPage,
      isFinpromptPages, hasBigScreenDesign,
    } = this.props;

    const showSubmitButton = !onboarding || (onboarding && width >= 2800);

    const firstNameValue = this.firstName?.value || '';
    const lastNameValue = this.lastName?.value || '';
    const emailValue = this.email?.value || '';
    const passwordValue = this.password?.value || '';
    const passwordConfirmationValue = this.passwordConfirmation?.value || '';

    let termsClass = '';

    if (termsChecked) {
      termsClass = 'active';
    } else if (errorTerms) {
      termsClass = 'error-checkbox';
    }

    return (
      <form
        onSubmit={(e) => this.validateRegisterForm(e)}
        ref={this.form}
      >
        <label className={cx('first-name')}>
          <input
            className={cx({ 'error-field': errorFirstName })}
            type="text"
            name="firstName"
            ref={(input) => {
              this.firstName = input;
            }}
            placeholder="First Name *"
            onChange={() => this.handleChangeFields('firstName')}
          />
          {errorFirstName && isFinpromptPages && firstNameValue?.length
            ? <span className={cx('error-field--text')}>{fieldsErrors?.firstName}</span> : null}
        </label>
        <label className={cx('last-name')}>
          <input
            className={cx({ 'error-field': errorLastName })}
            type="text"
            name="lastName"
            ref={(input) => {
              this.lastName = input;
            }}
            placeholder="Last Name *"
            onChange={() => this.handleChangeFields('lastName')}
          />
          {errorLastName && isFinpromptPages && lastNameValue?.length
            ? <span className={cx('error-field--text')}>{fieldsErrors?.lastName}</span> : null}
        </label>
        <label className={cx('email')}>
          <input
            className={cx({ 'error-field': errorEmail })}
            type="email"
            name="email"
            ref={(input) => {
              this.email = input;
            }}
            placeholder="Email *"
            onChange={() => this.handleChangeFields('email')}
          />
          {errorEmail && isFinpromptPages && emailValue?.length ? <span className={cx('error-field--text')}>{fieldsErrors?.email}</span> : null}
        </label>
        <label className={cx('password')}>
          <input
            className={cx({ 'error-field': errorPassword })}
            type="password"
            name="password"
            ref={(input) => {
              this.password = input;
            }}
            placeholder="Password *"
            onChange={() => this.handleChangeFields('password')}
          />
          {errorPassword && isFinpromptPages && passwordValue?.length ? <span className={cx('error-field--text')}>{fieldsErrors?.password}</span> : null}
        </label>
        <label className={cx('password-confirmation')}>
          <input
            className={cx({ 'error-field': errorConfirmPassword })}
            type="password"
            name="passwordConfirmation"
            ref={(input) => {
              this.passwordConfirmation = input;
            }}
            placeholder="Password confirmation *"
            onChange={() => this.handleChangeFields('passwordConfirmation')}
          />
          {errorConfirmPassword && isFinpromptPages && passwordConfirmationValue?.length ? <span className={cx('error-field--text')}>{fieldsErrors?.confirmPassword}</span> : null}
        </label>
        <div className={cx('checkboxes', { 'sign-in': signInPage },
          { finprompt: isFinpromptPages },
          { big_screen: hasBigScreenDesign })}
        >
          <div className={cx('checkbox_wrapper')}>
            <div className={cx('checkbox', { [`${termsClass}`]: true })}>
              <label>
                <input
                  type="checkbox"
                  checked={termsChecked}
                  onChange={() => this.checkTerms()}
                />
                <span className={cx('checkbox_material')}>
                  <span className={cx('check')}>
                    {termsChecked && <FontAwesomeIcon icon={faCheck} />}
                  </span>
                </span>
                <div>
                  Accept
                  {' '}
                  <a href="/terms" target="_blank">Terms of Service</a>
                  {' '}
                  and the
                  {' '}
                  <a href="/privacy" target="_blank">Privacy and Cookies Policy</a>
                  {' '}
                  *
                  {isFinpromptPages && (
                    <div>
                      and CityFALCONS's
                      {' '}
                      <a href="https://www.cityfalcon.ai/terms" target="_blank" rel="noreferrer">Terms of Service</a>
                      {' '}
                      and the
                      {' '}
                      <a href="https://www.cityfalcon.ai/privacy" target="_blank" rel="noreferrer">Privacy and Cookies Policy</a>
                      {' '}
                    </div>
                  )}
                </div>
              </label>
            </div>
          </div>
          {!isFinpromptPages && (
            <div className={cx('checkbox_wrapper')}>
              <div className={cx('checkbox', { active: alertsChecked })}>
                <label>
                  <input type="checkbox" checked={alertsChecked} onChange={this.checkAlerts} />
                  <span className={cx('checkbox_material')}>
                    <span className={cx('check')}>
                      {alertsChecked && <FontAwesomeIcon icon={faCheck} />}
                    </span>
                  </span>
                  <p>
                    Receive alerts for your watchlist by email
                  </p>
                </label>
              </div>
            </div>
          )}
          <div className={cx('checkbox_wrapper')}>
            <div className={cx('checkbox', { active: occasionalChecked })}>
              <label>
                <input type="checkbox" checked={occasionalChecked} onChange={this.checkOccasional} />
                <span className={cx('checkbox_material')}>
                  <span className={cx('check')}>
                    {occasionalChecked && <FontAwesomeIcon icon={faCheck} />}
                  </span>
                </span>
                <p>
                  Receive occasional emails about new features and promotions(optional)
                </p>
              </label>
            </div>
          </div>
        </div>
        <div className={cx('error', { show: errorMessage })}>
          <span>{errorMessage}</span>
        </div>
        {showSubmitButton && (
          <input
            className={cx('submit', { active: activeBtn, finprompt: isFinpromptPages },
              { big_screen: hasBigScreenDesign })}
            type="submit"
            value="Register"
          />
        )}
        {onboarding && (
          <OnboardingStepNav
            activeBtn={activeBtn}
            activePage="Register"
            valueRegisterButton="Register"
            history={history}
            width={width}
            piwikEnabled={piwikEnabled}
            user={user}
            handleClickRegister={this.onboardingHandleClickRegister}
          />
        )}
      </form>
    );
  }
}

const mapToDispatchProps = (dispatch) => ({
  setUserHasAccountModal: (value) => dispatch(setUserHasAccountModal(value)),
});

export default connect(null, mapToDispatchProps)(RegistrationComponent);
