import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faApple } from '@fortawesome/free-brands-svg-icons/faApple';

export const signInOptionsData = (twitter, facebook, google, linkedin, apple) => [
  {
    title: 'twitter',
    id: 1,
    icon: faTwitter,
    cssClass: 'tw',
    url: twitter,
    onboardingAction: 'Register Twitter Onboarding',
  },
  {
    title: 'facebook',
    id: 2,
    icon: faFacebookF,
    cssClass: 'fb',
    url: facebook,
    onboardingAction: 'Register Facebook Onboarding',
  },
  {
    title: 'google',
    id: 3,
    icon: faGoogle,
    cssClass: 'gl',
    url: google,
    onboardingAction: 'Register Google Onboarding',
  },
  {
    title: 'linkedin',
    id: 4,
    icon: faLinkedinIn,
    cssClass: 'in',
    url: linkedin,
    onboardingAction: 'Register Linkedin Onboarding',
  },
  {
    title: 'apple',
    id: 5,
    icon: faApple,
    cssClass: 'apple',
    url: apple,
    onboardingAction: 'Register Apple Onboarding',
  },
];

export const filteredPaths = ['/news/directory', '/news', '/search', '/pricing', '/news/stories'];
