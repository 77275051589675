import { Component, createRef } from 'react';
import autoBind from 'react-autobind';
import classNames from 'classnames/bind';
import axios from 'axios';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import loadable from '../../utils/loadable';

import { filteredPaths } from '../../data/authData';

import OnboardingStepNav from '../Onboarding/OnboardingStepNav';

import { isValidEmail } from '../../helpers/commonHelpers';
import { removeSentimentFromPath } from '../../helpers/topicsHelpers';
import { getCsrfParam, getCsrfToken } from '../../helpers/railsHelpers';
import { savePreferencesOnSubmit } from '../../helpers/authenticationHelpers';

import { setFinPromptAuthModalOpened, setForgotPasswordCustomModalOpened } from '../../actions/authentication.actions';

import StorageSvc from '../../services/StorageSvc';

import Styles from './styles/styles.module.scss';

const ForgotPasswordModal = loadable(() => import('../Modals/AuthModals/ForgotPasswordModal'));

const cx = classNames.bind(Styles);

class Login extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      errorMessage: '',
      errorEmail: false,
      errorPassword: false,
      activeBtn: false,
      openedModal: false,
      fieldsErrors: {
        email: 'Incorrect email format',
        password: 'Wrong password',
      },
    };
    this.email = createRef();
    this.password = createRef();
    this.form = createRef();
  }

  handleChangeFields(fieldName) {
    const email = this.email.current.value;
    const password = this.password.current.value;

    this.setState({
      errorMessage: '',
    });

    const validationResults = {
      email: isValidEmail(email),
      password: password.length >= 8,
    };

    const regexIsEmail = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;

    if (fieldName === 'email' && (!email.length
      || (email.length && (email.indexOf('@') === -1 || email.indexOf('.') === -1))
    || !regexIsEmail.test(email))) {
      this.setState({
        errorEmail: true,
      });
    } else if (fieldName === 'email') {
      this.setState({
        errorEmail: false,
      });
    }
    if (fieldName === 'password' && (!password.length || (password.length && password.length < 8))) {
      this.setState({
        errorPassword: true,
      });
    } else if (fieldName === 'password') {
      this.setState({
        errorPassword: false,
      });
    }

    const isValidFields = Object.values(validationResults).every(Boolean);

    this.setState({ activeBtn: isValidFields });
  }

  validateLoginForm(event) {
    event.preventDefault();

    const { activeBtn } = this.state;

    this.handleChangeFields('email');
    this.handleChangeFields('password');

    if (!activeBtn) return;

    this.submitLogin();
  }

  submitLogin() {
    const { redirectPath, onboarding, isFinpromptPages } = this.props;
    const email = this.email.current.value;
    const password = this.password.current.value;

    const dataCf = {
      user: {
        email,
        password,
      },
      [getCsrfParam()]: getCsrfToken(),
      redirect_path: redirectPath,
    };
    const dataFinPrompt = {
      email,
      password,
      landing_platform: 'finprompt',
    };
    const correctDataSignIn = isFinpromptPages ? dataFinPrompt : dataCf;

    const apiPathSignIn = isFinpromptPages ? '/webapi/v1/users/sign_in' : '/users/sign_in';

    axios.post(apiPathSignIn, correctDataSignIn)
      .then(({ data }) => {
        if (data.error) {
          return this.setState({ errorMessage: 'Email and/or password not recognised, please try again' });
        }

        // TODO instead of just adding this variable we need to refactor filters transferring logic
        StorageSvc.setItem('filtersTransferred', 'true');
        if (redirectPath === '/chatbot') {
          StorageSvc.setItem('show_demo_chatbot_status', 'true');
        }
        if (isFinpromptPages) {
          Cookies.set('api_token', data.token, { expires: 365 });
        }
        if (onboarding) {
          savePreferencesOnSubmit({ onboarding });
        }
        if (redirectPath && !isFinpromptPages) {
          window.location.href = removeSentimentFromPath(redirectPath);
          StorageSvc.removeItem('selectedOrderTab');
          StorageSvc.removeItem('selectedSentimentTab');
          return;
        }
        if (
          data.redirect_path
          && !filteredPaths.some((path) => window.location.pathname.startsWith(path))
        ) {
          window.location.href = removeSentimentFromPath(data.redirect_path);
        } else {
          window.location.reload();
        }
        this.setState({ errorMessage: '' });
        StorageSvc.removeItem('selectedOrderTab');
        StorageSvc.removeItem('selectedSentimentTab');
      }).catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          return this.setState({ errorMessage: 'Email and/or password not recognised, please try again' });
        }
        if (error.response?.data?.error) {
          return this.setState({ errorMessage: error.response.data.error });
        }
      });
  }

  onboardingHandleClickSignin() {
    this.form.current.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true }),
    );
  }

  toggleForgotPassModal(open) {
    const {
      isFinpromptPages,
    } = this.props;

    const {
      setFinPromptAuthModalOpened,
      setForgotPasswordCustomModalOpened,
    } = this.props;

    if (isFinpromptPages) {
      setForgotPasswordCustomModalOpened(true);
      setFinPromptAuthModalOpened(false);
      return;
    }
    this.setState({
      openedModal: open,
    });
  }

  render() {
    const {
      onboarding, width, history, piwikEnabled, user,
      isFinpromptPages, hasBigScreenDesign,
    } = this.props;
    const {
      errorEmail,
      errorPassword,
      activeBtn,
      errorMessage,
      openedModal,
      fieldsErrors,
    } = this.state;

    const showSubmitButton = !onboarding || (onboarding && width >= 2800);

    const emailValue = this.email.current?.value || '';
    const passwordValue = this.password.current?.value || '';

    return (
      <form
        ref={this.form}
        onSubmit={(e) => this.validateLoginForm(e)}
      >
        <label className={cx('email')}>
          <input
            className={cx('login-email', { 'error-field': errorEmail })}
            type="email"
            name="email"
            ref={this.email}
            placeholder="Email *"
            onChange={() => this.handleChangeFields('email')}
          />
          {errorEmail && isFinpromptPages && emailValue?.length
            ? <span className={cx('error-field--text')}>{fieldsErrors?.email}</span> : null}
        </label>
        {isFinpromptPages && (
          <div className={cx('login-socials-finprompt')}>
            Using socials instead of email
            {' '}
            <a target="_blank" href="https://www.cityfalcon.ai/help/articles/4405932982417" rel="noreferrer">Read this.</a>
          </div>
        )}
        <label className={cx('password')}>
          <input
            className={cx('login-password', { 'error-field': errorPassword })}
            type="password"
            name="password"
            ref={this.password}
            placeholder="Password *"
            onChange={() => this.handleChangeFields('password')}
          />
          {errorPassword && isFinpromptPages && passwordValue?.length
            ? <span className={cx('error-field--text')}>{fieldsErrors?.password}</span> : null}
        </label>
        <div className={cx('error', { show: errorMessage?.length })}>
          <span>{errorMessage}</span>
        </div>
        {showSubmitButton && (
          <input
            className={cx('submit', { active: activeBtn, finprompt: isFinpromptPages, big_screen: hasBigScreenDesign })}
            type="submit"
            value="Sign in"
          />
        )}
        <p
          onClick={() => this.toggleForgotPassModal(true)}
          className={cx('forgot-pass')}
          data-forgot-password
        >
          Forgot your password?
        </p>
        {!isFinpromptPages ? (
          <ForgotPasswordModal
            setModalOpened={this.toggleForgotPassModal}
            show={openedModal}
          />
        ) : null}
        {onboarding && (
          <OnboardingStepNav
            activeBtn={activeBtn}
            activePage="Register"
            valueRegisterButton="Sign in"
            history={history}
            width={width}
            piwikEnabled={piwikEnabled}
            user={user}
            handleClickRegister={this.onboardingHandleClickSignin}
          />
        )}
      </form>
    );
  }
}

const mapToDispatchProps = (dispatch) => ({
  setFinPromptAuthModalOpened: (value) => dispatch(setFinPromptAuthModalOpened(value)),
  setForgotPasswordCustomModalOpened: (value) => dispatch(setForgotPasswordCustomModalOpened(value)),
});

export default connect(null, mapToDispatchProps)(Login);
