import axios from 'axios';
import { isMobileOnly } from 'react-device-detect';

import { сreateNewWatchlistOnboarding } from '../services/dbServices/onboardingDbData';
import StorageSvc from '../services/StorageSvc';

import getUserToken from './getUserToken';
import { checkedDuplicateWatchlistName, formatTopicsQuery } from './watchlistHelperFunctions';

const filteredPaths = ['/news/stories'];

const state = StorageSvc.getItem('state');
const stateCach = (state && JSON.parse(state));
const languageFilter = JSON.parse(StorageSvc.getItem('LanguageFilter')) || {};
const watchlist = JSON.parse(StorageSvc.getItem('watchlist')) || [];

const languages = [];
languageFilter?.dropdown?.selected.map((item) => languages.push(item.short));

const userSimplifiedView = stateCach?.storyFilters[isMobileOnly ? 'user_newsfeed_advanced_view' : 'user_newsfeed_advanced_view_web'];
const userNewsfeedViewCompact = stateCach?.storyFilters[isMobileOnly ? 'user_newsfeed_compactview' : 'user_newsfeed_compactview_web'];
const userNewsfeedView = stateCach?.storyFilters[isMobileOnly ? 'user_newsfeed_view' : 'user_newsfeed_view_web'];

export const createWatchlistOnSubmit = () => {
  try {
    if (watchlist?.length) {
      watchlist.forEach((watchlist) => {
        const query = formatTopicsQuery(watchlist.search_queries);

        сreateNewWatchlistOnboarding(
          query, getUserToken(), checkedDuplicateWatchlistName(watchlist.name, []),
        );
      });
    }
  } catch (error) {
    console.log(error);
  } finally {
    [
      'watchlist',
    ].forEach((key) => StorageSvc.removeItem(key));
  }
};

export const savePreferencesOnSubmit = ({
  onboarding,
  data,
  redirectPath,
  registerSubmit,
  savedLanguages,
  isFinpromptPages,
}) => {
  axios.patch(
    '/webapi/v1/users/preference',
    {
      categories: stateCach.storyFilters.categories,
      languages: !onboarding ? savedLanguages : languages.join(),
      min_score: 5,
      [isMobileOnly ? 'user_newsfeed_advanced_view' : 'user_newsfeed_advanced_view_web']: userSimplifiedView,
      [isMobileOnly ? 'user_newsfeed_view' : 'user_newsfeed_view_web']: userNewsfeedView,
      [isMobileOnly ? 'user_newsfeed_compactview' : 'user_newsfeed_compactview_web']: userNewsfeedViewCompact,
    },
    {
      params: {
        api_key: getUserToken(),
      },
    },
  ).finally(() => {
    if (!isFinpromptPages && redirectPath && registerSubmit) {
      window.location.href = redirectPath;
      return;
    }

    // TODO: we need to add redirecting to new finprompt project here to RegisteredSuccessfully page

    if (!isFinpromptPages
      && data?.redirect_path
      && !filteredPaths.some((path) => window.location.pathname.startsWith(path))
      && registerSubmit
    ) {
      window.location.href = data.redirect_path;
    } else if (isFinpromptPages) {
      window.location.href = '/register';
    } else if (registerSubmit) {
      window.location.reload();
    }
    [
      'cf_onb_segments',
      'cf_onb_topicclasses',
      'cf_onb_selected_sug',
      'cf_onb_selected_sug_topic',
    ].forEach((key) => StorageSvc.removeItem(key));
  });
};
