import { Component } from 'react';
import autoBind from 'react-autobind';
import classNames from 'classnames/bind';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { connect } from 'react-redux';

import Alert from '../Shared/ReactAlert';

import loadable from '../../utils/loadable';

import { signInOptionsData, filteredPaths } from '../../data/authData';

import { getParamValueFromUrl } from '../../helpers/commonHelpers';
import { removeSentimentFromPath } from '../../helpers/topicsHelpers';

import RegistrationComponent from './RegistrationComponent';
import Login from './Login';
import Tooltip from '../Tooltip';

import withFinprompt from '../../decorators/withFinprompt';

import StorageSvc from '../../services/StorageSvc';

import Styles from './styles/styles.module.scss';

const cx = classNames.bind(Styles);

export class AuthenticationInner extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      params: [],
      activeAuth: '',
      redirectPath: '',
      showTooltipMessage: false,
      showTwitterTTMessage: false,
      clickCount: 0,
    };
    this.isShowFBMessageChecked = false;
  }

  componentDidMount() {
    const { selectedForm } = this.props;
    let urlParams = this.setUrlParams();
    urlParams = selectedForm === 'register'
      ? urlParams.filter((param) => (param.id !== 1 && param.id !== 2)) : urlParams;
    this.setState({
      params: urlParams,
    });
    this.isShowFBMessageChecked = JSON.parse(StorageSvc.getItem('showFBMessage'))?.status;
  }

  componentDidUpdate(prevProps) {
    const { selectedForm } = this.props;

    if (prevProps.selectedForm !== selectedForm) {
      let urlParams = this.setUrlParams();
      urlParams = selectedForm === 'register'
        ? urlParams.filter((param) => (param.id !== 1 && param.id !== 2)) : urlParams;
      this.setState({
        params: urlParams,
      });
    }
  }

  handleCheckChange(e) {
    e.stopPropagation();
    e.persist();
    this.isShowFBMessageChecked = !this.isShowFBMessageChecked;

    StorageSvc.setItem('showFBMessage', JSON.stringify({ status: this.isShowFBMessageChecked }));
  }

  handleLinkClick(e) {
    e.stopPropagation();
  }

  onMouseEnterHanlder(title) {
    if (title === 'facebook') return;
    this.setState({
      showTooltipMessage: false,
    });
  }

  setUrlParams() {
    const {
      segments = [],
      assetClasses = [],
      suggestedTopic,
      suggestedSegment,
      location,
      isFinpromptPages,
      authModalOpenedFromChatbot,
    } = this.props;

    let redirectPath;
    if (location?.pathname.startsWith('/sign_in')) {
      redirectPath = getParamValueFromUrl('redirect_page') ? decodeURIComponent(getParamValueFromUrl('redirect_page')) : '/';
    } else if (location?.pathname.startsWith('/news/onboarding')) {
      redirectPath = '/watchlists';
    } else if (isFinpromptPages) {
      redirectPath = '';
    } else if (authModalOpenedFromChatbot || location?.pathname === '/chatbot') {
      redirectPath = '/chatbot';
    } else {
      redirectPath = filteredPaths.some((path) => location?.pathname.startsWith(path)) ? (
        location?.pathname
      ) : '/watchlists';
    }

    const twitterLink = '/users/auth/twitter';
    const facebookLink = '/users/auth/facebook';
    const linkedinLink = '/users/auth/linkedin';
    const googleLink = '/users/auth/google_oauth2';
    const appleLink = '/users/auth/apple';

    let urlParams = '';

    if (!location?.pathname.startsWith('/sign_in')) {
      if (segments.length) {
        urlParams += `&segments=${segments.join(',')}`;
      }
      if (assetClasses.length) {
        urlParams += `&asset_classes=${assetClasses.join(',')}`;
      }
      if (suggestedTopic) {
        urlParams += `&suggested_topic_class=${suggestedTopic}`;
      }
      if (suggestedSegment) {
        urlParams += `&suggested_segment=${suggestedSegment}`;
      }
    }
    urlParams += `&redirect_path=${removeSentimentFromPath(redirectPath)}`;

    const twitterProxyLink = `/omniauth_proxy?social_url=${twitterLink}${urlParams}`;
    const facebookProxyLink = `/omniauth_proxy?social_url=${facebookLink}${urlParams}`;
    const linkedinProxyLink = `/omniauth_proxy?social_url=${linkedinLink}${urlParams}`;
    const googleProxyLink = `/omniauth_proxy?social_url=${googleLink}${urlParams}`;
    const appleProxyLink = `/omniauth_proxy?social_url=${appleLink}${urlParams}`;

    this.setState({
      redirectPath,
    });

    return signInOptionsData(
      twitterProxyLink,
      facebookProxyLink,
      googleProxyLink,
      linkedinProxyLink,
      appleProxyLink,
      redirectPath,
    );
  }

  // piwik action
  sendLoginAction(e, {
    onboardingAction: action, title, url, id,
  }) {
    if (e.target.id === 'fbMessageCheckBox' || title === 'twitter') return;
    const { clickCount } = this.state;
    if (id !== 2 || (id === 2 && this.isShowFBMessageChecked)) {
      this.setState({
        activeAuth: title,
      });
      StorageSvc.removeItem('selectedOrderTab');
      StorageSvc.removeItem('selectedSentimentTab');
      const { piwikEnabled } = this.props;

      if (piwikEnabled) _paq.push(['trackEvent', 'Login', `Login action: ${action}`]);
      window.location = url;
    } else if (id === 2 && clickCount !== 2) {
      this.setState(({ showTooltipMessage, clickCount }) => ({
        showTooltipMessage: !showTooltipMessage,
        clickCount: clickCount !== 2 ? clickCount + 1 : 0,
      }), () => {
        const { clickCount } = this.state;
        if (clickCount === 2) {
          this.setState({
            activeAuth: title,
          });
          StorageSvc.removeItem('selectedOrderTab');
          StorageSvc.removeItem('selectedSentimentTab');
          const { piwikEnabled } = this.props;

          if (piwikEnabled) _paq.push(['trackEvent', 'Login', action]);
          window.location = url;
        }
      });
    }
  }

  render() {
    const {
      params,
      activeAuth,
      redirectPath,
      showTooltipMessage,
    } = this.state;
    const {
      selectedForm, auth, location, history, piwikEnabled, user, width,
      hasBigScreenDesign, signInPage, cookiesPolicyStatus,
      isFinpromptPages,
    } = this.props;

    const onboarding = location?.pathname === '/news/onboarding/registration';

    const formClass = cx({
      'auth-form': true,
      'register-form': selectedForm === 'register',
      'login-form': selectedForm === 'login',
      onboarding,
      big_screen: hasBigScreenDesign,
      cookies: cookiesPolicyStatus,
      finprompt: isFinpromptPages,
    });

    const benefitsClass = cx({
      'auth-benefits': true,
    });

    const formContentClass = cx({
      'auth-form__content': true,
    });

    return (
      <section className={formClass}>
        {!isFinpromptPages && (
          <div className={benefitsClass}>
            <h3 className="auth-benefits__title">
              Benefits include:
            </h3>
            <ul className="auth-benefits__items">
              <li
                className={cx('auth-benefits__item')}
              >
                <FontAwesomeIcon icon={faCheck} />
                Personalised news
              </li>
              <li
                className={cx('auth-benefits__item')}
              >
                <FontAwesomeIcon icon={faCheck} />
                Like, dislike, and hide stories
              </li>
              <li
                className={cx('auth-benefits__item')}
              >
                <FontAwesomeIcon icon={faCheck} />
                Smart notifications
              </li>
              <li
                className={cx('auth-benefits__item')}
              >
                <FontAwesomeIcon icon={faCheck} />
                Seamless sharing with your network
              </li>
            </ul>
            <p className="auth-benefits__premium">
              Check out our
              <a
                href="/pricing"
              >
                &nbsp;Premium plans&nbsp;
              </a>
              for more cool features.
            </p>
          </div>
        )}
        <div className={formContentClass}>
          {!isFinpromptPages && (
            <>
              <div className={Styles.social}>
                {params.map((item) => (
                  <div
                    className={cx(item.cssClass, { loading: activeAuth === item.title })}
                    key={item.id}
                    onMouseEnter={() => this.onMouseEnterHanlder(item.title)}
                    onClick={(e) => this.sendLoginAction(e, item)}
                  >
                    {
                  activeAuth === item.title ? (
                    <div className={Styles.spinner}>
                      <FontAwesomeIcon icon={faSpinner} />
                    </div>
                  ) : (
                    <FontAwesomeIcon icon={item.icon} />
                  )
                }
                    {
                  item.title === 'twitter' && (
                    <Tooltip
                      icon={<div className={cx('tooltip')} />}
                      className={cx('tw_tooltip')}
                    >
                      Unfortunately, Twitter
                        {' '}
                        {'doesn\'t'}
                        {' '}
                      allow us to use this feature anymore.
                        {' '}
                      <a
                        href="https://www.cityfalcon.ai/help/articles/4405932982417-Trouble-logging-in-with-non-email-accounts"
                        target="_blank"
                        rel="noreferrer"
                        onClick={this.handleLinkClick}
                      >
                        See solutions
                      </a>
                    </Tooltip>
                  )
                }
                    {item.id === 2 && !this.isShowFBMessageChecked && (
                    <span className={cx('tooltipContent', { show: showTooltipMessage })}>
                      <p className={cx('content')}>
                        The login may not work for some users.
                        {' '}
                        <a
                          href="https://www.cityfalcon.ai/help/articles/4405932982417-Trouble-logging-in-with-non-email-accounts"
                          target="_blank"
                          rel="noreferrer"
                          onClick={this.handleLinkClick}
                        >
                          See solutions
                        </a>
                      &nbsp;&nbsp;or click the icon again to continue.
                      </p>
                      <label
                        className={cx('fbLabel')}
                      >
                        Don&apos;t show it again
                        <input
                          id="fbMessageCheckBox"
                          type="checkbox"
                          value={this.isShowFBMessageChecked}
                          name="fbMessageShow"
                          checked={this.isShowFBMessageChecked}
                          onChange={this.handleCheckChange}
                        />
                        <span
                          id="fbMessageCheckBox"
                          className={cx('checkmark')}
                        />
                      </label>
                    </span>
                    )}
                  </div>
                ))}
              </div>
              <p>or</p>
            </>
          )}
          {selectedForm === 'login' ? (
            <Login
              auth={auth}
              redirectPath={redirectPath}
              onboarding={onboarding}
              width={width}
              history={history}
              piwikEnabled={piwikEnabled}
              user={user}
              signInPage={signInPage}
              isFinpromptPages={isFinpromptPages}
              hasBigScreenDesign={isFinpromptPages}
            />
          ) : (
            <RegistrationComponent
              auth={auth}
              redirectPath={redirectPath}
              onboarding={onboarding}
              history={history}
              piwikEnabled={piwikEnabled}
              user={user}
              width={width}
              signInPage={signInPage}
              isFinpromptPages={isFinpromptPages}
              hasBigScreenDesign={isFinpromptPages}
            />
          )}
          <Alert stack={false} timeout={3000} />
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  cookiesPolicyStatus: state.common.cookiesPolicyStatus,
  authModalOpenedFromChatbot: state.authentication.authModalOpenedFromChatbot,
});

export default withRouter(connect(mapStateToProps)(withFinprompt(AuthenticationInner)));
